<template>
  <v-select
    v-model="currentLocale"
    class="langSwitch"
    :items="regionStore.availableLocales"
    item-title="name"
    item-value="code"
    return-object
    rounded
    variant="plain"
  >
    <template #selection="{ item }">
      <v-list-item>
        <client-only>
          <component :is="getFlag(item.raw.code)" style="max-width: 10rem; max-height: 2.5rem" />
        </client-only>
      </v-list-item>
    </template>
    <template #item="{ props, item }">
      <v-list-item v-bind="props" :title="undefined">
        <v-img :src="getFlagUrl(item.raw.code)" style="max-width: 3rem; max-height: 1.2rem" />
      </v-list-item>
    </template>
  </v-select>
</template>

<script setup lang="ts">

const { locale, locales } = useI18n();
const regionStore = useRegionStore();

const currentLocale = ref(
  (locales.value).find(i => (i.code as string) === locale.value)
);

watch(currentLocale, () => {
  if (!currentLocale.value) { return; }
  regionStore.changeLanguage(currentLocale.value.code);
});

function getFlag (name: string | undefined): ReturnType<typeof defineAsyncComponent> {
  return defineAsyncComponent(() =>
    import(`../../assets/svg/flag-${name}.svg`)
      .catch(() => {
        // eslint-disable-next-line no-console
        console.warn(`Failed to load flag ${name}`);
        return { render: () => "" };
      })
  );
}

function getFlagUrl (name: string | undefined): string {
  if (!name) { return ""; }
  return new URL(`../../assets/svg/flag-${name}.svg`, import.meta.url).href;
}
</script>
